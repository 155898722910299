<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Tour - Nuevo</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" @submit.prevent="Validate">
              <b-row>
                <b-col md="3">
                  <b-form-group label="Ruta:">
                    <b-form-select v-model="tour.id_route" :options="routes"></b-form-select>
                    <small v-if="errors.id_route"  class="form-text text-danger" >Selecciona una ruta</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Unidad:">
                    <b-form-select v-model="tour.id_bus" :options="buses"></b-form-select>
                    <small v-if="errors.id_bus"  class="form-text text-danger" >Selecciona una unidad</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Hora de Salida:">
                    <b-form-input class="text-center" type="time" step="any" v-model="tour.departure_time"></b-form-input>
                    <small v-if="errors.departure_time"  class="form-text text-danger" >Ingrese una Hora de Salida</small>
                  </b-form-group>
                </b-col>

                <b-col md="3">
                  <b-form-group label="Lugar de Salida:">
                    <b-form-input v-model="tour.place_departure"></b-form-input>
                    <small v-if="errors.place_departure"  class="form-text text-danger" >Ingrese un lugar</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Duración Aprox:">
                    <b-form-input v-model="tour.approximate_duration"></b-form-input>
                    <small v-if="errors.approximate_duration"  class="form-text text-danger" >Ingrese un duración</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Precio Soles:">
                    <b-form-input class="text-right" type="number" step="any" v-model="tour.price_pen"></b-form-input>
                    <small v-if="errors.price_pen"  class="form-text text-danger" >Ingrese un precio</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Precio Dólares:">
                    <b-form-input class="text-right" type="number" step="any" v-model="tour.price_usd"></b-form-input>
                    <small v-if="errors.price_usd"  class="form-text text-danger" >Ingrese un precio</small>
                  </b-form-group>
                </b-col>

                <b-col md="8">
                  <b-form-group label="Descripción:">
                    <b-form-textarea type="text" rows="2" v-model="tour.description"></b-form-textarea>
                    <small v-if="errors.description" class="form-text text-danger" >Ingrese una descripción</small>
                  </b-form-group>
                </b-col>

              

                <b-col md="5"></b-col>
                <b-col md="2">
                  <b-button type="submit" class="form-control" variant="primary" ><i class="fas fa-save"></i> Guardar (F4)</b-button>
                </b-col>
                
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <LoadingComponent :is-visible="isLoading"/>
    <Keypress key-event="keyup" :key-code="115" @success="Validate" />
  </div>
</template>

<script>

const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");

import { mapState,mapMutations,mapActions } from "vuex";
// components
import LoadingComponent from './../pages/Loading'
export default {
  name: "UsuarioAdd",
  components:{
      Keypress: () => import('vue-keypress'),
      LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      module: 'Tour',
      role: 2,
      tour: {
          id_route:'',
          id_bus:'',
          code:'',
          departure_time:'',
          place_departure:'',
          approximate_duration:'',
          description:'',
          price_pen:'0.00',
          price_usd:'0.00',
          state:1,
      },
      buses:[],
      routes:[],
      errors: {
        id_bus: false,
        id_route: false,
        departure_time: false,
        place_departure: false,
        approximate_duration: false,
        description: false,
        price_pen: false,
        price_usd: false,
      },
      validate: false,
    };
  },
  mounted() {
   this.ListBus();
   this.ListRoute();
  },
  methods: {
    AddTour,
    Validate,
    ListBus,
    ListRoute,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
  },
};


function ListBus() {
  let me = this;
  let url = this.url_base + "bus/select-active";
  me.isLoading = true;
  axios({
    method: "GET",
    url: url,
    headers: {token: this.token, module: this.module,role: this.role},
  })
    .then(function (response) {
      me.buses = [{value:'',text:'Seleccione una unidad'}];
      if (response.data.status == 200) {
        for (let index = 0; index < response.data.result.length; index++) {
          const element = response.data.result[index];
          me.buses.push({value:element.id_bus,text:element.name});
        }
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text:"A ocurrido un error", timer: 3000,})
      me.isLoading = false;
    });
}

function ListRoute() {
  let me = this;
  let url = this.url_base + "route/select-active";
  me.isLoading = true;
  axios({
    method: "GET",
    url: url,
    headers: {token: this.token, module: this.module,role: this.role},
  })
    .then(function (response) {
      me.routes = [{value:'',text:'Seleccione una ruta'}];
      if (response.data.status == 200) {
        for (let index = 0; index < response.data.result.length; index++) {
          const element = response.data.result[index];
          me.routes.push({value:element.id_route,text:element.name});
        }
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text:"A ocurrido un error", timer: 3000,})
      me.isLoading = false;
    });
}

function AddTour() {
  let me = this;
  let url = me.url_base + "tour/add";
  let data = me.tour;
  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role},
  })
    .then(function (response) {
      if (response.data.status == 201) {
        me.tour.id_route = '';
        me.tour.id_bus = '';
        me.tour.departure_time = '';
        me.tour.price_pen = '0.00';
        me.tour.price_usd = '0.00';
        me.tour.description = '';
        me.tour.state = 1;
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}

function Validate() {

  this.errors.id_bus = this.tour.id_bus.length == 0 ? true : false;
  this.errors.id_route = this.tour.id_route.length == 0 ? true : false;
  this.errors.departure_time = this.tour.departure_time.length == 0 ? true : false;
  this.errors.place_departure = this.tour.place_departure.length == 0 ? true : false;
  this.errors.approximate_duration = this.tour.approximate_duration.length == 0 ? true : false;
  this.errors.price_pen = this.tour.price_pen.length == 0 ? true : false;
  this.errors.price_usd = this.tour.price_usd.length == 0 ? true : false;
  

  if (this.errors.id_bus) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.id_route) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.departure_time) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.place_departure) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.approximate_duration) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.price_pen) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.price_usd) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }


  let me = this;
  Swal.fire({
    title: "Esta seguro de registrar el tour ?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.AddTour();
    }
  });
}
</script>
